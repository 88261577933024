/* eslint-disable */
<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>Websocket to receive Data</v-card-title>
      <v-card-text>Populates and builds form below</v-card-text>
      <v-row>
        <v-card-text>
          <v-card>
            <vue-webrtc
              ref="webrtc"
              width="100%"
              :room-id="roomId"
              socket-u-r-l="https://pharmaguide.ngrok.io"
              @joined-room="logEvent"
              @left-room="logEvent"
              @opened-room="logEvent"
              @share-started="logEvent"
              @share-stopped="logEvent"
              @error="onError"
            />
          </v-card>
        </v-card-text>
        <v-col class="col-md-12 my-3">
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onJoin"
          >
            Join
          </v-btn>
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onLeave"
          >
            Leave
          </v-btn>
          <!-- <button type="button" class="btn btn-primary" @click="onCapture">Capture Photo</button>
            <button type="button" class="btn btn-primary" @click="onShareScreen">Share Screen</button> -->
        </v-col>
      </v-row>
    </v-card>
    <!-- <v-card> -->
    <!-- <span>{{ $socket.connected ? 'Connected' : 'Disconnected' }}</span> -->
    <!-- {{ this.messages }} -->
    <!-- </v-card> -->
    <!-- <v-dialog
    v-model="signatureStatus"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  > -->
    <v-card v-show="signatureStatus">
      <VueSignaturePad
        :key="refreshSignatureStatus"
        ref="signaturePad"
        width="500px"
        height="500px"
        :options="{ onBegin, onEnd }"
      />
      <div>
        <v-btn
          color="primary"
          @click="save"
        >
          Save
        </v-btn>
        <v-btn
          color="primary"
          @click="undo"
        >
          Undo
        </v-btn>
        <v-btn
          color="primary"
          @click="hideSignaturePanel"
        >
          Close Signature
        </v-btn>
      </div>
    </v-card>
    <v-card>
      <v-card-text flat>
        <FormulateForm
          v-model="formValues"
          :schema="items[0].schema"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import { WebRTC } from 'vue-webrtc'

const socket = io('https://pharmaguide.ngrok.io')

Vue.component(WebRTC.name, WebRTC)

// const socket = io('https://pharmaguide.ngrok.io')

Vue.use(VueSocketIOExt, socket)

// https://github.com/probil/vue-socket.io-extended
export default {
  name: 'Portal',
  data() {
    return {
      newMessage: null,
      refreshSignatureStatus: 0,
      messages: [],
      typing: false,
      username: null,
      ready: false,
      info: [],
      connections: 0,
      img: null,
      roomId: '',
      signatureStatus: false,
      formValues: {
        // cheese: 0,
        // phone: 6472217547,
      },
      items: [
        {
          title: 'Pt',
          id: 1,
          schema: [
            {
              component: 'h3',
              class: 'text-2xl mb-4',
              children: 'Pt',
            },
            {
              type: 'select',
              label: 'Pizza size',
              name: 'size',
              placeholder: 'Select a size',
              options: {
                small: 'Small',
                large: 'Large',
                extra_large: 'Extra Large',
              },
              validation: 'required',
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  name: 'cheese',
                  label: 'LV Mass & Wall Thickness',
                  type: 'checkbox',
                  validation: 'min:1,length',
                  options: {
                    'Normal LV mass, size & wall Thickness': 'Normal LV mass, size & wall Thickness',
                    'Normal LV mass': 'Normal LV mass',
                    'Proximal Septal thickening': 'Proximal Septal thickening',
                    'Proximal Septal thickenting (mild)': 'Proximal Septal thickenting (mild)',
                  },
                  'outer-class': ['w-1/2'],
                },
                {
                  name: 'toppings',
                  label: 'EF ASE 2015',
                  type: 'checkbox',
                  validation: 'min:2,length',
                  options: {
                    salami: 'ASE 2015(male)',
                    prosciutto: 'ASE 2015(female)',
                    avocado: 'mild 41-45%',
                    onion: 'mild to moderate ~40%',
                  },
                  'outer-class': ['w-1/2'],
                },
              ],
            },
            {
              component: 'div',
              class: 'flex',
              children: [
                {
                  type: 'select',
                  name: 'country_code',
                  label: 'Code',
                  value: '1',
                  'outer-class': ['w-1/4 mr-4'],
                  options: {
                    1: '+1',
                    49: '+49',
                    55: '+55',
                  },
                },
                {
                  type: 'text',
                  label: 'Phone number',
                  name: 'phone',
                  inputmode: 'numeric',
                  pattern: '[0-9]*',
                  validation: 'matches:/^[0-9-]+$/',
                  'outer-class': ['flex-grow'],
                  'validation-messages': {
                    matches: 'Phone number should only include numbers and dashes.',
                  },
                },
                {
                  label: 'Slider example?',
                  type: 'range',
                  name: 'range',
                  min: '0',
                  max: '100',
                  value: '45',
                  validation: 'required|min:10|max:90',
                  'error-behavior': 'live',
                },
              ],
            },
            {
              type: 'submit',
              label: 'Order pizza',
            },
          ],
        },
      ],
    }
  },
  sockets: {
    beforeMount() {
      this.roomId = this.$route.params.roomId
    },
    connect() {
      console.log('socket connected')
    },
    customEmit(val) {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    },
    message(data) {
      this.messages.push(data)
      this.fillfrom()
    },
  },
  mounted() {
    // subscribe
    this.$socket.client.on('eventName', this.onEventName) // <-- this.onEventName here
  },
  beforeDestroy() {
    // unsubscribe
    this.$socket.client.off('eventName', this.onEventName) // <-- this.onEventName here
  },
  methods: {
    onBegin() {
      console.log('=== Begin ===')
    },
    onEnd() {
      //   if ((this.refreshSignatureStatus = 1)) {
      // this.refreshSignatureStatus += 1
      //   }

      console.log('=== End ===')
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      console.log(isEmpty)
      console.log(data)
    },
    hideSignaturePanel() {
      this.signatureStatus = false
    },
    fillfrom() {
      this.formValues = this.messages[0]
      this.signatureStatus = true
      this.refreshSignatureStatus += 1
    },
    clickButton(val) {
      // this.$socket.client is `socket.io-client` instance
      this.$socket.client.emit('emit_method', val)
    },
    onEventName(params) {
      console.log('`eventName` has fired with:', params)
    },
    onCapture() {
      this.img = this.$refs.webrtc.capture()
    },
    onJoin() {
      this.$refs.webrtc.join()
    },
    onLeave() {
      this.$refs.webrtc.leave()
    },
    onShareScreen() {
      this.img = this.$refs.webrtc.shareScreen()
    },
    onError(error, stream) {
      console.log('On Error Event', error, stream)
    },
    logEvent(event) {
      console.log('Event : ', event)
    },
  },
}
</script>
